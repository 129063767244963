.aboutme-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 12rem 4rem;
  gap: 10%;
}
.aboutme-textcontainer {
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding: 4rem 0rem;
}
.quote {
  filter: invert(100%);
}
.aboutme-sec1 {
  display: flex;
  grid-column: span 1;
  align-items: flex-start;
  flex-direction: column;
}
.aboutme-sec1-mobile {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.aboutme-sec2 {
  display: flex;
  grid-column: span 1;
  align-items: flex-start;
  flex-direction: column;
}

.aboutme-sec2-mobile {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.aboutme-title {
  color: #fff;
  font-family: Host;
  text-transform: uppercase;
  font-size: 3rem;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  padding: 0;
  padding-top: 2rem;
  margin: 0;
  line-height: 100%;
}
.about-myloc {
  color: rgb(139, 139, 139);
  font-family: Host;
  font-size: 18px;
  text-align: left;
  font-style: normal;
  font-weight: 300;
  padding: 0;
  padding-top: 3rem;
  margin: 0;
  line-height: 100%;
}
.aboutme-sub {
  color: #fffa;
  text-align: left;
  font-family: Host;
  font-size: 2rem;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}
.aboutme-detail {
  color: #fffa;
  text-align: left;
  text-transform: uppercase;
  font-family: Host;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}
@media (max-width: 1600px) {
  .aboutme-sec2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .aboutme-sec1 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}

@media (max-width: 1000px) {
  .about-myloc {
    font-size: 14px;
  }
  .aboutme-sub {
    font-size: 2rem;
  }
  .aboutme-title {
    font-size: 3rem;
  }
  .aboutme-detail {
    font-size: 1.5rem;
  }
  .aboutme-container {
    padding: 6rem 2rem;
  }
}

@media (max-width: 800px) {
  .aboutme-sub {
    font-size: 1.5rem;
  }
  .aboutme-title {
    font-size: 2rem;
  }
}

.aboutme-textcontainer-mobile {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
