.contact-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100vw;
  min-height: 90vh;
  gap: 48px;
  border-top: 1px solid #fff2;
}
.contact-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
}

.contact-img-container {
  height: 100%;
  grid-column: span 1;
  object-fit: fill;
  z-index: 0;
}

.contact-content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 24px 48px;
  z-index: 1;
  grid-column: span 2;
  height: 100%;
}

.contact-title {
  color: #fff;
  position: relative;
  z-index: 1;
  font-family: Host;
  font-size: 10rem;
  font-style: normal;
  text-align: left;
  font-weight: 800;
  margin: 0;
  line-height: 100%;
}
.contact-myloc {
  color: rgb(139, 139, 139);
  font-family: Host;
  text-transform: uppercase;
  text-align: left;
  font-size: 1rem;
  z-index: 1;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin: 0;
}

.final-contact {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 70%;
}

@media (max-width: 1200px) {
  .contact-title {
    font-size: 8rem;
  }
}

.contact-link-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 32px;
  padding: 32px 0px;
}
@media (max-width: 1500px) {
  .contact-content {
    grid-column: span 3;
  }
}

@media (max-width: 900px) {
  .contact-title {
    font-size: 4rem;
  }
  .contact-content {
    min-height: 90vh;
    height: auto;
    padding: 48px 32px;
    gap: 32px;
    justify-content: flex-start;
  }
  .contact-container {
    min-height: 90vh;
    height: auto;
  }
}

@media (max-width: 1200px) {
  .contact-link-container {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 24px;
    margin-left: -16px;
  }
}

.contact-page-container {
  background-color: #000;
}
