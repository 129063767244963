.preloader-container {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preloader-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.preloader-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
}
.preloader-text {
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.preloader-logo {
  height: 5vh;
}
.preloader-title {
  color: white;
  text-align: center;
  font-size: 4rem;
  color: #fff;
  z-index: 1;
  font-family: Host, sans-serif;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  margin: 0;
}
@media (max-width: 1000px) {
  .preloader-title {
    font-size: 3rem;
  }
}
