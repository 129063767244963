.projectsheader-container {
  display: flex;
  width: 85%;
  padding: 80px 145px;
  padding-top: 0px;
  flex-direction: column;
  gap: 60px;
}
.projectsheader-content {
  display: grid;
  place-items: center;
  place-content: center;
  margin-bottom: 10vh;
}
.projectsheader-text {
  height: 20%;
}
.projectsheader-title {
  color: #fff;
  position: relative;
  z-index: 1;
  font-family: "DM Sans", sans-serif;
  font-size: 6rem;
  font-style: normal;
  margin: 0;
  font-weight: 800;
  line-height: 100%;
}
.projectsheader-title-mobile {
  position: relative;
  width: 90svw;
  margin: 0;
  padding: 0;
  color: #fff;
  z-index: 1;
  font-family: "DM Sans", sans-serif;
  font-size: 16svw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.projectsheader-image {
  z-index: 0;
  margin-top: 25vh;
  opacity: 0.5;
  position: relative;
  height: 800px;
}
.projectsheader-image-mobile {
  z-index: 0;
  margin-top: 25vh;
  opacity: 0.5;
  position: relative;
  height: 800px;
}

.projheader-myloc {
  color: rgb(139, 139, 139);
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  text-align: left;
  font-style: normal;
  font-weight: 300;
  padding: 0;
  padding-top: 3vh;
  margin: 0;
  line-height: 100%;
}
@media (max-width: 800px) {
  .projectsheader-title {
    color: #fff;
    position: relative;
    z-index: 1;
    font-family: "DM Sans", sans-serif;
    font-size: 8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
}
@media (max-width: 700px) {
  .projheader-myloc {
    color: rgb(139, 139, 139);
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    font-style: normal;
    font-weight: 300;
    padding: 0;
    padding-top: 3vh;
    margin: 0;
    line-height: 100%;
  }
}

.project-button {
  display: flex;
  border: 1px solid white;
  border-radius: 50px;
  padding: 12px 24px;
  font-family: Host;
  color: #fff;
  font-family: Host;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0px;
  transition: 0.4s ease-in-out;
}

.project-button:hover {
  background-color: white;
  padding: 12px 32px;
  color: black;
}
