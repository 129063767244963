.darkness {
  height: 40vh;
  background-color: #000;
}
.case-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  width: 100%;
}
.section-container {
  width: 80%;
  display: flex;
  gap: 600px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.section-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vh;
}

.section-sub {
  color: #fff8;
  width: fit-content;
  font-family: Host;
  font-size: 1rem;
  text-align: left;
  text-transform: uppercase;
  font-style: normal;
  margin: 0;
  font-weight: 300;
  line-height: 100%;
}

.section-title {
  color: white;
  font-family: Host;
  font-size: 2.5rem;
  text-transform: uppercase;
  text-align: left;
  font-style: normal;
  margin: 0;
  font-weight: 700;
}

.section-title-fancy {
  color: white;
  font-family: Host;
  text-transform: uppercase;
  font-size: 3rem;
  text-align: center;
  font-style: normal;
  width: 100%;
  margin: 0;
  font-weight: 800;
}

.section-text {
  color: #fff8;
  font-family: Host;
  font-size: 1.2rem;
  text-align: left;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  font-weight: 300;
}
.section-text b {
  color: #fff;
}

.dmo-title {
  color: white;
  font-family: Host;
  font-size: 1.2rem;
  text-align: left;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  font-weight: 400;
}

.section-color-tile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px;
}

.colorpick {
  width: 3rem;
  height: 3rem;
  border-radius: 50px;
  border: 0.5px solid #fff;
}
.colors {
  display: flex;
  gap: 3rem;
}
.section-image-container {
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
}

.section-image {
  width: 100%;
  border-radius: 24px;
}
.font-svg {
  height: 48px;
}
@media (max-width: 700px) {
  .dmo-title {
    font-size: 1rem;
  }
  .section-text {
    font-size: 1rem;
  }
  .section-title-fancy {
    font-size: 2.5rem;
    text-align: left;
  }
  .section-sub {
    font-size: 1rem;
  }
  .font-svg {
    height: 24px;
  }
  .section-title {
    font-size: 2rem;
  }

  .section-color-tile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0px;
  }

  .colorpick {
    width: 2rem;
    height: 2rem;
    border-radius: 50px;
    border: 0.5px solid #fff;
  }
  .colors {
    display: flex;
    gap: 0.7rem;
  }
  .section-image-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
  .logo {
    scale: 0.6;
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  40% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  90% {
    background-position: 0% 50%;
  }
}

.fanciness {
  background-image: linear-gradient(40deg, #e63946, #e96d77, #e63946);
  background-size: 200% 100%;
  font-weight: 600;
  line-height: normal;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: gradientAnimation 10s linear infinite;
}

.section-image {
  width: 100%;
}
.fin {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 2rem;
  margin: 0;
  background-color: #000;
}
a {
  text-decoration: none;
  color: white;
}

.studycase {
  display: flex;
  z-index: 200;
  align-items: center;
  justify-content: center;
}
.studycase-btn {
  display: flex;
  border: 1px solid white;
  border-radius: 50px;
  padding: 12px 24px;
  color: #fff;
  font-family: Host;
  font-size: 1rem;
  font-weight: 600;
  transition: 0.4s ease-in-out;
}
.studycase-btn:hover {
  background-color: white;
  padding: 12px 32px;
  color: black;
}

.studycase-txt {
  margin: 0;
}

.avatar-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 100%;
}

.avatars-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.avatar-container {
  display: flex;
  flex-direction: row;
  height: fit-content;
  gap: 1.2rem;
  align-items: flex-start;
  justify-content: center;
}

.avatar-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.5rem;
}

.avatar-name {
  font-family: Host;
  font-size: 1.2rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
}

.avatar-description {
  font-family: Host;
  font-size: 1rem;
  color: #fff8;
  font-weight: 400;
  margin: 0;
}

.cs-video-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cs-video {
  aspect-ratio: 9/19;
  max-height: 640px;
  object-fit: cover;
}

.research-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.research-container-item {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 1200px) {
  .cs-video-container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

@media (max-width: 900px) {
  .cs-video-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .research-container {
    flex-direction: column;
  }
  .research-container-item {
    width: 100%;
  }
}

.process-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  color: #fff;
  align-items: center;
  justify-content: flex-start;
}

.video-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 300vh;
  box-sizing: border-box;
  align-items: center;
}

.video-file {
  width: 100%;
  border-radius: 24px;
}

.case-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}
