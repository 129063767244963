.p404-container {
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 4rem 32rem;
  box-sizing: border-box;
}
.p404-text {
  font-family: Host;
  font-size: 6rem;
  text-transform: uppercase;
  font-weight: 800;
  margin: 0;
  line-height: 100%;
  text-align: center;
  transition: 0.4s ease-in-out;
}
.p404-text:hover {
  opacity: 0.8;
  letter-spacing: -2px;
  font-weight: 600;
}

@media (max-width: 900px) {
  .p404-container {
    gap: 1rem;
    padding: 2rem 24px;
  }
  .p404-text {
    font-size: 3rem;
  }
}
