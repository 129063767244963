.home {
  width: 100vw;
  box-sizing: border-box;
  padding: 4rem 0rem;
  padding-top: 10rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.arrow {
  animation: ripple 4s ease-in-out infinite;
}

@keyframes ripple {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.header-text {
  color: #fff;
  font-family: Host;
  font-size: 2rem;
  font-weight: 100;
  line-height: 100%;
}

.esmi {
  gap: 0svh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition-duration: 0.1s;
}

.esmi-text {
  color: #fff;
  font-family: Host;
  font-size: 6rem;
  text-align: left;
  margin: 0%;
  line-height: 1;
  font-weight: 800;
  transition: 0.4s ease-out;
  &:hover {
    color: #aaa;
    letter-spacing: -2px;
    font-weight: 600;
  }
}

.desc-text {
  color: #888;
  text-align: left;
  margin: 0;
  font-family: Host;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
}

.sub-text {
  color: #888;
  text-align: left;
  margin: 0;
  font-family: Host;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}

.role {
  display: flex;
  flex-direction: row;
  height: min-content;
  gap: 2vw;
  margin-top: 0;
  margin-bottom: 0;
}

.social-container {
  margin-top: 1rem;
  padding: 1vw;
  width: min-content;
  height: min-content;
}

.socials {
  display: flex;
  width: min-content;
  flex-direction: row;
  position: relative;
  padding: 16px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 56px;
  border: 3px solid rgba(255, 255, 255, 0.06);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(45px);
}

.social-icon {
  position: relative;
  width: 32px;
  height: 32px;
  pointer-events: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding: 24px 0px;
}

.mentions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  margin: 0;
  color: white;
}

.mentions-l {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0;
  color: white;
}

.available-icn {
  width: 12px;
  height: 12px;
  background-color: rgb(43, 221, 43);
  border-radius: 50px;
}

.mentions_txt {
  font-size: 1rem;
  margin: 0;
  font-family: Host;
  color: inherit;
}
.mentions_link {
  color: inherit;
  text-decoration: none;
  font-weight: 800;
}

.social-container {
  z-index: 5;
}
.socials {
  z-index: 5;
}

.home-img {
  height: 600px;
}

.home-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 32px;
  width: 100%;
  padding: 0px 48px;
  justify-content: space-between;
  box-sizing: border-box;
}

@media (max-width: 900px) {
  .esmi-text {
    font-size: 6rem;
  }
  .home-container {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 24px;
  }
  .home-img {
    height: auto;
  }
  .esmi-text {
    font-size: 4rem;
  }
}

@media (max-width: 640px) {
  .esmi-text {
    font-size: 3rem;
  }
  .home {
    padding-top: 6rem;
  }
}

@media (max-height: 700px) {
  .home {
    padding-top: 6rem;
  }
}
