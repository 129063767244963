.navbar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: row;
  padding: 24px 48px;
  background-color: #00000080;
  backdrop-filter: blur(200px);
  z-index: 11;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #fff2;
}

.nocolor {
  background-color: #00000000;
  backdrop-filter: blur(0px);
}

.header-logo {
  scale: 0.8;
}

.header-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  display: flex;
}

.contact-button {
  display: flex;
  border: 1px solid white;
  border-radius: 50px;
  padding: 12px 24px;
  color: #fff;
  font-family: Host;
  font-size: 1rem;
  font-weight: 600;
  transition: 0.4s ease-in-out;
}

.contact-button:hover {
  background-color: white;
  padding: 16px 32px;
  color: black;
}

.contact-btn-container {
  width: 180px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease-out;
}

.menu-icon:hover {
  transform: rotate(90deg);
}

@media (max-width: 640px) {
  .header {
    padding: 24px 32px;
  }

  .contact-btn-container {
    width: 180px;
    height: 32px;
  }

  .menu-icon {
    width: 32px;
    height: 32px;
  }
}

/*menu elements */

.menu-container {
  display: flex;
  box-sizing: border-box;
  z-index: 10;
  flex-direction: column;
  margin: 0;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  padding: 64px 0px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  backdrop-filter: blur(200px);
  color: white;
}

.menu-elements {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  gap: 24px;
}

.menu-element {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  font-family: Host;
  font-weight: 800;
  line-height: 100%;
  font-size: 6rem;
  padding: 0px;
  text-align: center;
  color: inherit;
  transition: 0.3s ease-out;
  letter-spacing: 0px;
}
.menu-element:hover {
  color: #aaa;
  letter-spacing: -2px;
  font-weight: 600;
}

.menu-link-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 32px;
  padding: 32px 64px;
}

.menu-link-element {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-family: Host;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  transition: 0.3s ease-in-out;
  padding: 8px 16px;
  margin: 0px;
  border-radius: 50px;
  color: #aaa;
}

.menu-link-text {
  margin: 0px;
  font-weight: 600;
  text-decoration: none;
  color: inherit;
}

.menu-link-element:hover {
  background-color: white;
  color: black;
}

@media (max-width: 900px) {
  .menu-element {
    font-size: 4rem;
  }

  .menu-link-container {
    flex-wrap: wrap;
    gap: 8px;
  }
}

@media (max-width: 500px) {
  .menu-element {
    font-size: 4rem;
  }
}
