.scroll-resetter {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 12px;
  color: #fff;
  transition: 0.4s ease-in-out;
  z-index: 1000;
}
.scroll-resetter:hover {
  background-color: white;
  padding: 12px 24px;
  color: black;
}
