.expertise-container {
  display: flex;
  width: 85%;
  padding: 80px 145px;
  padding-top: 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  gap: 60px;
}
.expertise-textcontainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0;
  gap: 64px;
}
.expertise-sec1 {
  display: flex;
  width: 100%;
  z-index: 1;
  padding-top: 15px;
  padding-bottom: 30px;
  align-items: center;
  flex-direction: column;
}

.expertise-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.expertiseelement {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  align-items: center;
  font-weight: 600;
}

.expertise-title {
  padding: 16px 32px;
  border-radius: 50px;
  border: 1px solid white;
  font-family: Host;
  font-size: 1rem;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin: 0px;
}
.expertise-type {
  font-family: Host;
  color: #cdb4db;
  font-size: 6rem;
  line-height: 100%;
  padding: 0px;
  margin: 0px;
}

.expertise-example {
  color: white;
  font-family: Host;
  font-size: 2rem;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  padding: 0;
  margin: 0;
  line-height: 100%;
}
.expertise-myloc {
  color: rgb(139, 139, 139);
  font-family: Host;
  font-size: 18px;
  text-align: left;
  font-style: normal;
  font-weight: 300;
  padding: 0;
  padding-top: 3vh;
  margin: 0;
  line-height: 100%;
}

@media (max-width: 600px) {
  .expertise-list {
    gap: 70px;
    margin-top: 2svh;
  }
  .expertise-title {
    margin: 0;
    line-height: 100%;
  }
  .expertiseelement {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: 0;
  }

  .expertise-type {
    font-size: 10svw;
  }
  .expertise-example {
    color: white;
    font-family: Host;
    font-size: 1.4rem;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    padding: 0;
    margin: 0;
    line-height: 140%;
  }
  .expertise-myloc {
    color: rgb(139, 139, 139);
    font-family: Host;
    font-size: 14px;
    text-align: left;
    font-style: normal;
    font-weight: 300;
    padding: 0;
    padding-top: 3vh;
    margin: 0;
    line-height: 100%;
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.fancy {
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.expertise-icons {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
